import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Map,
  AdvancedMarker,
  InfoWindow,
  useMap
} from '@vis.gl/react-google-maps';
import { req } from "utils/api";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "400px",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

const defaultCenter = { lat: -37.8136, lng: 144.9631 };
const defaultZoom = 7;

// Add these constants at the top of the file with other constants
const CIRCLE_SCALE = 60;
const CIRCLE_OPACITY = 0.8;
const CIRCLE_OPACITY_HOVER = 0.9;
const CIRCLE_STROKE_WIDTH = 1;
const CIRCLE_STROKE_WIDTH_HOVER = 2;

// Add these constants at the top of the file
const HOVER_DELAY = 200; // Increased delay for hover
const HOVER_EXIT_DELAY = 300; // Slightly longer delay for hover exit
const MARKER_BASE_SCALE = 30; // Smaller base scale for non-clustered markers
const MARKER_CLUSTER_SCALE = 60; // Original scale for clustered markers

// Custom Marker Component
const CustomMarker = ({ data, maxDeposits, onSelect, onHover }) => {
  const [isHovered, setIsHovered] = useState(false);
  const depositsCount = parseInt(data.total_deposits);
  
  // Validate coordinates
  const lat = parseFloat(data.latitude);
  const lng = parseFloat(data.longitude);
  
  console.log('=== Marker Data ===', {
    location_name: data.location_name,
    latitude: lat,
    longitude: lng,
    total_deposits: depositsCount
  });

  // Skip rendering if coordinates are invalid
  if (lat === 0 && lng === 0) {
    console.warn('Invalid coordinates for location:', data.location_name);
    return null;
  }

  let color = "#00FF00";
  if (depositsCount > 0.66 * maxDeposits) {
    color = "#FF0000";
  } else if (depositsCount > 0.33 * maxDeposits) {
    color = "#FFA500";
  }

  return (
    <AdvancedMarker
      position={{ lat, lng }}
      onClick={() => onSelect(data)}
      onMouseOver={() => {
        setIsHovered(true);
        onHover(data);
      }}
      onMouseOut={() => {
        setIsHovered(false);
        onHover(null);
      }}
    >
      <div
        style={{
          backgroundColor: color,
          borderRadius: "50%",
          width: isHovered ? "20px" : "15px",
          height: isHovered ? "20px" : "15px",
          border: "1px solid white",
          cursor: 'pointer',
          boxShadow: isHovered
            ? '0 4px 8px rgba(0,0,0,0.3)'
            : '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'all 0.2s ease-in-out'
        }}
      />
    </AdvancedMarker>
  );
};

export function StatsMap({ role, startDate, endDate, filterReports }) {
  console.log('=== StatsMap Component Mounted ===');
  console.log('Props:', { role, startDate, endDate, filterReports });

  const [deposits, setDeposits] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const maxDeposits = useMemo(() => {
    console.log('=== Calculating maxDeposits ===');
    console.log('Deposits length:', deposits.length);
    if (!deposits.length) return 0;
    const max = Math.max(...deposits.map(d => parseInt(d.total_deposits)));
    console.log('Max deposits:', max);
    return max;
  }, [deposits]);

  const getDeposits = useCallback(async () => {
    console.log('=== StatsMap: Fetching Deposits ===');
    console.log('Date range:', { startDate, endDate });
    console.log('Role:', role);
    console.log('Filter Reports:', filterReports);
    
    setIsLoading(true);
    setError(null);
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];
    let url = role === "admin" 
      ? `admin/get_map_deposits?startDate=${startDateString}&endDate=${endDateString}`
      : `customer/get_map_deposits?startDate=${startDateString}&endDate=${endDateString}`;

    // Add location filter if available
    if (filterReports?.propertyFilter?.length > 0) {
      url += `&locationsFilter=${filterReports.propertyFilter.join(",")}`;
    }

    console.log('Fetching from URL:', url);

    try {
      const res = await req("GET", url);
      console.log('=== API Response ===');
      console.log('Success:', res.success);
      console.log('Data count:', res.data?.length || 0);
      if (res.success) {
        console.log('Sample deposit:', res.data[0]);
        
        // Filter out locations with invalid coordinates
        const validDeposits = res.data.filter(deposit => {
          const lat = parseFloat(deposit.latitude);
          const lng = parseFloat(deposit.longitude);
          const isValid = lat !== 0 || lng !== 0;
          if (!isValid) {
            console.warn('Skipping location with invalid coordinates:', deposit.location_name);
          }
          return isValid;
        });
        
        console.log('Valid deposits:', validDeposits.length);
        console.log('Filtered deposits:', validDeposits);
        
        setDeposits(validDeposits);
        setIsDataLoaded(true);
        
        if (validDeposits.length === 0) {
          setError("No locations with valid coordinates found");
        }
      } else {
        console.error('API Error:', res.message);
        setError(res.message || "Failed to fetch data");
      }
    } catch (err) {
      console.error('=== API Error ===', err);
      setError(err.message || "An error occurred");
    }
    setIsLoading(false);
  }, [startDate, endDate, role, filterReports]);

  useEffect(() => {
    console.log('=== StatsMap: useEffect triggered ===');
    getDeposits();
  }, [getDeposits]);

  console.log('=== StatsMap: Rendering ===');
  console.log('State:', {
    isDataLoaded,
    isLoading,
    error,
    depositsCount: deposits.length,
    hasSelectedMarker: !!selectedMarker,
    hasHoveredMarker: !!hoveredMarker
  });

  return (
    <div className="flex flex-col flex-auto relative" style={{ minHeight: '400px', height: '100%' }}>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
          <div className="text-xl font-semibold">Loading...</div>
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
          <div className="text-red-500 text-xl font-semibold">{error}</div>
        </div>
      )}
      
      <Map
        mapId={process.env.REACT_APP_GOOGLE_MAPS_ID}
        style={mapContainerStyle}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        gestureHandling={'greedy'}
        disableDefaultUI={false}
      >
        {isDataLoaded && deposits.map((data) => (
          <CustomMarker
            key={data.location_g_id}
            data={data}
            maxDeposits={maxDeposits}
            onSelect={setSelectedMarker}
            onHover={setHoveredMarker}
          />
        ))}

        {hoveredMarker && !selectedMarker && (
          <InfoWindow
            position={{
              lat: parseFloat(hoveredMarker.latitude),
              lng: parseFloat(hoveredMarker.longitude),
            }}
            onCloseClick={() => setHoveredMarker(null)}
          >
            <div className="p-2">
              <h3 className="font-bold mb-1">{hoveredMarker.location_name}</h3>
              <p>
                <strong>Deposits:</strong>{" "}
                {parseInt(hoveredMarker.total_deposits).toLocaleString()}
              </p>
            </div>
          </InfoWindow>
        )}

        {selectedMarker && (
          <InfoWindow
            position={{
              lat: parseFloat(selectedMarker.latitude),
              lng: parseFloat(selectedMarker.longitude),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="p-3">
              <h2 className="font-bold text-lg mb-2">
                {selectedMarker.location_name}
              </h2>
              <p className="mb-1">
                <strong>Deposits:</strong>{" "}
                {parseInt(selectedMarker.total_deposits).toLocaleString()} containers
              </p>
              {selectedMarker.material_type && (
                <p className="mb-1">
                  <strong>Material:</strong>{" "}
                  {selectedMarker.material_type}
                </p>
              )}
            </div>
          </InfoWindow>
        )}
      </Map>
    </div>
  );
}
