// 1. React Imports:
import { useCallback, useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import { format, subDays } from "date-fns";
import { DateRange } from "react-date-range";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// 3. Custom Hooks:
import { useAuth } from "../../contexts/AuthContext";

// 4. Component Imports:
import { ContainersTable } from "../../components/analytics/ContainersTable";
import { DepositDailyBarChart } from "../../components/analytics/DepositDailyBarChart";
import { EnvironmentalStats } from "../../components/analytics/EnvironmentalStats";
import { FilterReports } from "../../components/analytics/FilterReports";
import { MaterialPieChart } from "../../components/analytics/MaterialPieChart";

// 6. Utility Functions or Constants:
import { req } from "utils/api";
import { generateAnalyticsPDF } from "utils/pdfGenerator";

// 7. Assets:
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import PrintIcon from "@mui/icons-material/Print";
import NatureIcon from '@mui/icons-material/Nature';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import DeleteIcon from '@mui/icons-material/Delete';
import BoltIcon from '@mui/icons-material/Bolt';

const LoadingOverlay = ({ open, message }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={open}
    >
      <CircularProgress color="inherit" size={60} />
      <Typography variant="h6" component="div">
        {message}
      </Typography>
    </Backdrop>
  );
};

export function AnalyticsReports() {
  /*--------------------------------------
	State Variables and Hooks
  ---------------------------------------*/
  const { user } = useAuth();
  const role = user?.accountType;
  const baseUrl = role === "admin" ? "admin" : "customer";

  /* Data */
  const [filterCount, setFilterCount] = useState(0);
  const [filterReports, setFilterReports] = useState({
    propertyFilter: [],
    customerFilter: [],
  });
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [materialStats, setMaterialStats] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [carbonEmissions, setCarbonEmissions] = useState(0);
  const [drivingDistance, setDrivingDistance] = useState(0);
  const [waterSavings, setWaterSavings] = useState(0);
  const [showerMinutes, setShowerMinutes] = useState(0);
  const [landfillSavings, setLandfillSavings] = useState(0);
  const [landfillKgs, setLandfillKgs] = useState(0);
  const [energySavings, setEnergySavings] = useState(0);
  const [tvHours, setTvHours] = useState(0);

  /* Date Range Picker */
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(
    subDays(subDays(new Date(), 7), 30)
  );
  const [endDate, setEndDate] = useState(subDays(new Date(), 7));
  const open = Boolean(anchorEl);
  const dateRangePopoverId = open ? "date-range-popover" : undefined;
  const maxDate = subDays(new Date(), 7);
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  /* Drawer */
  const [drawerOpen, setDrawerOpen] = useState(false);

  /* Tabs */
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detects if the screen is a mobile device.

  /* Loading state */
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  /*--------------------------------------
	Derived Data and Functions
  ---------------------------------------*/

  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  const formatNumber = (value) => {
    return value.toLocaleString();
  };

  const formatLargeNumber = (num) => {
    if (!num || isNaN(num)) return '0';
    return new Intl.NumberFormat('en-US').format(num);
  };

  const calculateEnvironmentalImpact = (totalUnits) => {
    // These should match your web calculations
    const carbonPerUnit = 0.12; // kg of CO2 per container
    const waterPerUnit = 5.87; // litres per container
    const landfillPerUnit = 0.025; // kg per container
    const energyPerUnit = 1.2; // megajoules per container

    return {
      carbon: Math.round(totalUnits * carbonPerUnit),
      water: Math.round(totalUnits * waterPerUnit),
      landfill: Math.round(totalUnits * landfillPerUnit),
      energy: Math.round(totalUnits * energyPerUnit),
      // Derived calculations
      drivingDistance: Math.round((totalUnits * carbonPerUnit) / 0.271), // km (based on average car emissions)
      showerMinutes: Math.round((totalUnits * waterPerUnit) / 9), // minutes (based on average shower)
      tvHours: Math.round((totalUnits * energyPerUnit) / 0.1) // hours (based on average TV consumption)
    };
  };

  /*--------------------------------------
	Utility Functions
  ---------------------------------------*/

  function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*--------------------------------------
	Event Handlers
  ---------------------------------------*/

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPrint = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Generating Environmental Impact Page...');
      const impact = calculateEnvironmentalImpact(totalUnits);
      
      // Create and setup the PDF
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true
      });
      
      // Get page dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      // First page - Environmental Impact
      const firstPageDiv = document.createElement('div');
      firstPageDiv.appendChild(createHeaderSection(impact));
      document.body.appendChild(firstPageDiv);
      
      // Capture first page
      const firstPageCanvas = await html2canvas(firstPageDiv, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff'
      });
      document.body.removeChild(firstPageDiv);
      
      // Add first page to PDF
      const firstPageImage = firstPageCanvas.toDataURL('image/png', 1.0);
      const firstPageRatio = Math.min(
        pdfWidth / firstPageCanvas.width,
        pdfHeight / firstPageCanvas.height
      );
      pdf.addImage(
        firstPageImage,
        'PNG',
        0,
        0,
        firstPageCanvas.width * firstPageRatio,
        firstPageCanvas.height * firstPageRatio
      );
      
      // Update message before material types
      setLoadingMessage('Generating Material Types Chart...');
      setTabValue(1);
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const chartElement = document.querySelector('#material-pie-chart .bg-card');
      if (chartElement) {
        pdf.addPage();
        
        pdf.setFontSize(32);
        pdf.text('Material Types', pdfWidth / 2, 20, { align: 'center' });
        
        const chartCanvas = await html2canvas(chartElement, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });
        
        const chartImage = chartCanvas.toDataURL('image/png', 1.0);
        const chartRatio = Math.min(
          (pdfWidth - 20) / chartCanvas.width,
          (pdfHeight - 40) / chartCanvas.height
        );
        
        pdf.addImage(
          chartImage,
          'PNG',
          10,
          30,
          chartCanvas.width * chartRatio,
          chartCanvas.height * chartRatio
        );
      }

      // Update message before daily stats
      setLoadingMessage('Generating Daily Statistics Chart...');
      setTabValue(2);
      await new Promise(resolve => setTimeout(resolve, 2000));

      const dailyStatsElement = document.querySelector('#daily-bar-chart .bg-card');
      if (dailyStatsElement) {
        pdf.addPage();
        
        pdf.setFontSize(32);
        pdf.text('Daily Statistics', pdfWidth / 2, 20, { align: 'center' });
        
        const dailyStatsCanvas = await html2canvas(dailyStatsElement, {
          scale: 3,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff',
          width: dailyStatsElement.offsetWidth,
          height: dailyStatsElement.offsetHeight,
        });
        
        const dailyStatsImage = dailyStatsCanvas.toDataURL('image/png', 1.0);
        
        // Use full page width with minimal margins
        const margin = 5; // 5mm margin on each side
        const imageWidth = pdfWidth - (margin * 2);
        const imageHeight = (dailyStatsCanvas.height / dailyStatsCanvas.width) * imageWidth;
        
        // If height would exceed page, scale down proportionally
        if (imageHeight > (pdfHeight - 40)) { // 40mm for title and margins
          const scale = (pdfHeight - 40) / imageHeight;
          imageHeight *= scale;
          imageWidth *= scale;
        }
        
        // Center horizontally
        const xPosition = margin;
        
        pdf.addImage(
          dailyStatsImage,
          'PNG',
          xPosition,
          30, // Fixed top position
          imageWidth,
          imageHeight
        );
      }
      
      // Final save message
      setLoadingMessage('Saving PDF...');
      setTabValue(0);
      
      // Save the PDF
      pdf.save('analytics_report.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeDate = (ranges) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    const adjustedEndDate = newEndDate > maxDate ? maxDate : newEndDate;
    setStartDate(newStartDate);
    setEndDate(adjustedEndDate);
  };

  const handleClickOpenDrawer = () => {
    // setCustomer(customer); // Set the customer to be edited
    setDrawerOpen(true); // Open the drawer
  };

  /*--------------------------------------
	Effects
  ---------------------------------------*/

  useEffect(() => {
    fetchProperties();
    document.title = "Analytics Reports";
  }, []);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterReports]);

  /*--------------------------------------
	Async Functions
  ---------------------------------------*/

  const fetchProperties = async () => {
    try {
      //   console.log("Fetching Properties");

      // Fetch properties from the API
      const properties = await req("GET", "has_access_to_properties");

      // Ensure that properties is an array and filter out empty location names, then map to location_name
      const filteredProperties = properties.filter((property) =>
        property?.location_name?.trim()
      ); // Ensures location_name is not empty or only spaces

      // Update the state with the filtered properties
      setPropertyOptions(filteredProperties);

      // If there is only one property set the default filter reports
      if (filteredProperties.length === 1) {
        setFilterReports({
          propertyFilter: [filteredProperties[0].location_id],
          customerFilter: [],
        });
        setFilterCount(1);
      }
    } catch (error) {
      // Handle errors (e.g., network error, invalid data, etc.)
      console.error("Error fetching properties:", error);
      // Optionally, you can set a state to show an error message to the user
      setPropertyOptions([]); // Clear or set a fallback state on error
    }
  };

  const fetchData = useCallback(async () => {
    // console.log("Fetching Data");

    // Format the start and end dates
    let formattedStart = encodeURIComponent(
      startDate.toISOString().split("T")[0]
    );
    let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);

    // Base URL
    let url = `${baseUrl}/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`;

    // Check for selected properties in filterReports
    if (
      filterReports.propertyFilter &&
      filterReports.propertyFilter.length > 0
    ) {
      // Extract location_id values and join them into a comma-separated string
      const locationIds = filterReports.propertyFilter.join(",");

      // Add the locationsFilter parameter to the URL
      url += `&locationsFilter=${locationIds}`;
    }

    // Fetch data
    const stats = await req("GET", url);

    // Process stats and update state
    const units = calculateTotalUnits(stats);
    setTotalUnits(units);
    setMaterialStats(stats);

    // Calculate additional stats
    const carbonEmissions = stats.reduce((total, stat) => total + parseFloat(stat.carbon_emissions), 0);
    const drivingDistance = stats.reduce((total, stat) => total + parseFloat(stat.driving_distance), 0);
    const waterSavings = stats.reduce((total, stat) => total + parseFloat(stat.water_savings), 0);
    const showerMinutes = stats.reduce((total, stat) => total + parseFloat(stat.shower_minutes), 0);
    const landfillSavings = stats.reduce((total, stat) => total + parseFloat(stat.landfill_savings), 0);
    const landfillKgs = stats.reduce((total, stat) => total + parseFloat(stat.landfill_kgs), 0);
    const energySavings = stats.reduce((total, stat) => total + parseFloat(stat.energy_savings), 0);
    const tvHours = stats.reduce((total, stat) => total + parseFloat(stat.tv_hours), 0);

    setCarbonEmissions(carbonEmissions);
    setDrivingDistance(drivingDistance);
    setWaterSavings(waterSavings);
    setShowerMinutes(showerMinutes);
    setLandfillSavings(landfillSavings);
    setLandfillKgs(landfillKgs);
    setEnergySavings(energySavings);
    setTvHours(tvHours);
  }, [baseUrl, startDate, endDate, filterReports]);

  // Add these helper functions before handleClickPrint
  const createSection = (content, styles = '') => {
    const section = document.createElement('div');
    section.style.cssText = `
      background-color: white;
      padding: 24px;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      margin-bottom: 16px;
      width: 100%;
      ${styles}
    `;
    section.innerHTML = content;
    return section;
  };

  const createPanel = (icon, title, value, subtitle, valueColor, highlightColor) => `
    <div style="
      background-color: white;
      border-radius: 16px;
      padding: 32px;
      text-align: center;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      border: 1px solid #e2e8f0;
    ">
      <div style="margin-bottom: 24px; display: flex; justify-content: center;">
        <div style="width: 48px; height: 48px; display: flex; align-items: center; justify-content: center;">
          ${getIconSvg(icon)}
        </div>
      </div>
      <div style="font-size: 20px; color: #1e293b; margin-bottom: 24px;">
        ${title}
      </div>
      <div style="font-size: 36px; color: ${valueColor}; margin-bottom: 16px; font-weight: 500;">
        ${value}
      </div>
      <div style="color: #64748b; font-size: 16px; line-height: 1.5;">
        ${subtitle}
      </div>
    </div>
  `;

  const getIconSvg = (iconType) => {
    switch(iconType) {
      case 'leaf':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#22c55e" style="display: block;">
          <path d="M6.05 8.05a7.001 7.001 0 0 0-.02 9.88c1.47-3.4 4.09-6.24 7.36-7.93A15.952 15.952 0 0 0 8 19.32c2.79 1.74 6.21 2.23 9.41 1.14C16.26 13.63 8.15 5.53 1.32 4.38c-.91 3.2-.42 6.62 1.32 9.41a15.952 15.952 0 0 0 9.32-4.61c-1.69 3.27-4.53 5.89-7.93 7.36z"/>
        </svg>`;
      case 'water':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#0ea5e9" style="display: block;">
          <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"/>
        </svg>`;
      case 'trash':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#16a34a" style="display: block;">
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
        </svg>`;
      case 'energy':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#f59e0b" style="display: block;">
          <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"/>
        </svg>`;
    }
  };

  const createHeaderSection = (impact) => {
    let properties = 'All Properties';
    if (filterReports.propertyFilter.length > 0) {
      properties = 'Properties included: ' + filterReports.propertyFilter
        .map(id => propertyOptions.find(p => p.location_id === id)?.location_name)
        .join(', ');
    }
    
    return createSection(`
      <h1 style="font-size: 48px; font-weight: 600; margin-bottom: 16px; text-align: center; color: #1e293b;">Environmental Impact</h1>
      <p style="color: #64748b; margin: 0; text-align: center;">
        ${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}
      </p>
      <p style="color: #64748b; margin: 0; text-align: center; margin-bottom: 48px;">
        ${properties}
      </p>
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px;">
        ${createPanel(
          'leaf',
          'Carbon emissions avoided',
          `${formatLargeNumber(impact.carbon)} Kg of CO2`,
          `That's the same amount that a car would emit by driving for <span style="color: #22c55e; font-weight: 500;">${formatLargeNumber(impact.drivingDistance)} kilometers</span>`,
          '#22c55e',
          '#22c55e'
        )}
        ${createPanel(
          'water',
          'Water savings',
          `${formatLargeNumber(impact.water)} litres`,
          `That's the equivalent of <span style="color: #0ea5e9; font-weight: 500;">${formatLargeNumber(impact.showerMinutes)} minutes</span> of showering`,
          '#0ea5e9',
          '#0ea5e9'
        )}
        ${createPanel(
          'trash',
          'Landfill savings',
          `${formatLargeNumber(impact.landfill)} Kilograms`,
          `You've saved <span style="color: #16a34a; font-weight: 500;">${formatLargeNumber(impact.landfill)} kgs</span> of material from going to landfill`,
          '#16a34a',
          '#16a34a'
        )}
        ${createPanel(
          'energy',
          'Energy savings',
          `${formatLargeNumber(impact.energy)} Megajoules`,
          `That's enough energy to power your TV for <span style="color: #f59e0b; font-weight: 500;">${formatLargeNumber(impact.tvHours)} hours</span>`,
          '#f59e0b',
          '#f59e0b'
        )}
      </div>
    `);
  };

  const createContainersSection = () => {
    return createSection(`
      <div style="text-align: center;">
        <div style="font-size: 64px; color: #1e40af; margin-bottom: 8px;">
          ${formatLargeNumber(totalUnits)}
        </div>
        <div style="font-size: 18px; color: #4B5563;">
          Containers Recycled
        </div>
      </div>
    `);
  };

  const createEnvironmentalSection = (impact) => {
    return createSection(`
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px;">
        ${createPanel(
          'leaf',
          'Carbon emissions avoided',
          `${formatLargeNumber(impact.carbon)} Kg of CO2`,
          `That's the same amount that a car would emit by driving for <span style="color: #22c55e; font-weight: 500;">${formatLargeNumber(impact.drivingDistance)} kilometers</span>`,
          '#22c55e',
          '#22c55e'
        )}
        ${createPanel(
          'water',
          'Water savings',
          `${formatLargeNumber(impact.water)} litres`,
          `That's the equivalent of <span style="color: #0ea5e9; font-weight: 500;">${formatLargeNumber(impact.showerMinutes)} minutes</span> of showering`,
          '#0ea5e9',
          '#0ea5e9'
        )}
        ${createPanel(
          'trash',
          'Landfill savings',
          `${formatLargeNumber(impact.landfill)} Kilograms`,
          `You've saved <span style="color: #16a34a; font-weight: 500;">${formatLargeNumber(impact.landfill)} kgs</span> of material from going to landfill`,
          '#16a34a',
          '#16a34a'
        )}
        ${createPanel(
          'energy',
          'Energy savings',
          `${formatLargeNumber(impact.energy)} Megajoules`,
          `That's enough energy to power your TV for <span style="color: #f59e0b; font-weight: 500;">${formatLargeNumber(impact.tvHours)} hours</span>`,
          '#f59e0b',
          '#f59e0b'
        )}
      </div>
    `);
  };

  return (
    <>
      {propertyOptions.length > 1 && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "600px",
            },
          }}
        >
          <FilterReports
            closeDrawer={closeDrawer}
            filterReports={filterReports}
            setFilterReports={setFilterReports}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
            propertyOptions={propertyOptions}
          />
        </Drawer>
      )}
      <Box 
        id="analytics-content" 
        className="flex flex-auto flex-col w-full relative print:p-0 print:shadow-none print:bg-transparent"
        sx={{
          '@media print': {
            padding: 0,
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }
        }}
      >
        <div className="flex flex-auto flex-col min-w-0 w-full dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="bg-card flex flex-col items-start border-b dark:bg-transparent sm:flex-row sm:items-center sm:justify-between p-6 sm:py-12 md:px-8">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                  Analytics: Reports
                </h1>
                <div className="subtitle">
                  * Data is current up to 7 days ago.
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="flex flex-row justify-between md:justify-end"
              >
                <div>
                  <Button
                    className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                    startIcon={<CalendarTodayIcon />}
                    disableElevation
                    onClick={handleOpenPopover}
                  >
                    {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                  </Button>
                  <Popover
                    id={dateRangePopoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <DateRange
                      ranges={[selectionRange]}
                      onChange={handleChangeDate}
                      moveRangeOnFirstSelection={false}
                      maxDate={maxDate}
                    />
                  </Popover>
                </div>
                <div className="flex">
                  {propertyOptions.length > 1 && (
                    <Button
                      variant="outlined"
                      className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                      startIcon={<FilterListIcon />}
                      disableElevation
                      onClick={handleClickOpenDrawer}
                    >
                      {filterCount && filterCount > 0
                        ? `${filterCount} Filters`
                        : "Filter"}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <PrintIcon />}
                    disableElevation
                    onClick={handleClickPrint}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Generating PDF...' : 'Print PDF'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex flex-auto overflow-hidden">
            <div className="flex flex-auto flex-col overflow-hidden sm:overflow-y-auto p-6">
              <Box className="w-full pb-6 md:pb-8">
                <Box style={{ boxShadow: "inset 0 -1px var(--fuse-border)" }}>
                  {isMobile ? (
                    <Select
                      value={tabValue}
                      onChange={(event) =>
                        handleChangeTab(null, event.target.value)
                      }
                      fullWidth
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value={0}>Environmental Impact</MenuItem>
                      <MenuItem value={1}>Material Types</MenuItem>
                      <MenuItem value={2}>Daily Stats</MenuItem>
                      <MenuItem value={3}>Charity Donations</MenuItem>
                    </Select>
                  ) : (
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="responsive tabs example"
                    >
                      <Tab label="Environmental Impact" {...a11yProps(0)} />
                      <Tab label="Material Types" {...a11yProps(1)} />
                      <Tab label="Daily Stats" {...a11yProps(2)} />
                      <Tab label="Charity Donations" {...a11yProps(3)} />
                    </Tabs>
                  )}
                </Box>
              </Box>
              <Box classname="w-full">
                <TabPanel value={tabValue} index={0}>
                  <div id="environmental-stats">
                    <EnvironmentalStats totalUnits={totalUnits} />
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <div id="material-pie-chart" className="flex flex-auto flex-col w-full">
                    <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 py-12 shadow">
                      <MaterialPieChart materialStats={materialStats} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <div id="daily-bar-chart" className="flex flex-auto flex-col w-full">
                    <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                      <DepositDailyBarChart materialStats={materialStats} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <div className="flex h-full flex-auto flex-col items-center justify-center">
                    <div className="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none">
                      Charity Donations
                    </div>
                    <div className="text-secondary mt-2 text-center text-lg font-medium tracking-tight md:text-xl">
                      Sorry for the inconvenience, Charity Donations should be
                      available soon!
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </Box>
      <LoadingOverlay open={isLoading} message={loadingMessage} />
    </>
  );
}
