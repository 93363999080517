// 1. React Imports:
import { useCallback, useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import { format, subDays } from "date-fns";
import { DateRange } from "react-date-range";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// 3. Custom Hooks:
import { useAuth } from "contexts/AuthContext";

// 4. Component Imports:
import { ContainersTable } from "../../components/analytics/ContainersTable";
import { DepositDailyBarChart } from "../../components/analytics/DepositDailyBarChart";
import { EnvironmentalStats } from "../../components/analytics/EnvironmentalStats";
import { FilterReports } from "../../components/analytics/FilterReports";
import { GoogleMapsLoader } from "../../components/analytics/GoogleMapsLoader";
import { MaterialPieChart } from "../../components/analytics/MaterialPieChart";
import { StatsMap } from "../../components/analytics/StatsMap";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PrintIcon from "@mui/icons-material/Print";
import logoUrl from "../../assets/images/Tomra_sh_logo_white.svg";

// --------------------------------------------------------------

const LoadingOverlay = ({ open, message }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={open}
    >
      <CircularProgress color="inherit" size={60} />
      <Typography variant="h6" component="div">
        {message}
      </Typography>
    </Backdrop>
  );
};

export function CustomerHome() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const { user } = useAuth();
  const role = user?.accountType;

  // Data
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [filterReports, setFilterReports] = useState({
    propertyFilter: [],
    customerFilter: [],
  });
  const [materialStats, setMaterialStats] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);

  /* Date Picker */
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(
    subDays(subDays(new Date(), 7), 30)
  );
  const [endDate, setEndDate] = useState(subDays(new Date(), 7));
  const open = Boolean(anchorEl);
  const dateRangePopoverId = open ? "date-range-popover" : undefined;
  const maxDate = subDays(new Date(), 7);
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  /* Drawer */
  const [drawerOpen, setDrawerOpen] = useState(false);

  /* Tabs */
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detects if the screen is a mobile device.

  /* Loading state */
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  const calculateEnvironmentalImpact = (totalUnits) => {
    // These are the environmental impact multipliers
    const multipliers = {
      carbon: 0.12, // kg CO2 per container
      water: 5.87, // litres per container
      landfill: 0.03, // kg per container
      energy: 2.5, // megajoules per container
    };

    return {
      carbon: totalUnits * multipliers.carbon,
      water: totalUnits * multipliers.water,
      landfill: totalUnits * multipliers.landfill,
      energy: totalUnits * multipliers.energy,
      drivingDistance: (totalUnits * multipliers.carbon) / 0.192, // km (assuming 0.192 kg CO2 per km)
      showerMinutes: (totalUnits * multipliers.water) / 10, // minutes (assuming 10L per minute)
      tvHours: (totalUnits * multipliers.energy) / 0.3, // hours (assuming 0.3 MJ per hour)
    };
  };

  const formatLargeNumber = (number) => {
    // Implementation of formatLargeNumber function
    // This is a placeholder and should be replaced with the actual implementation
    return number.toLocaleString();
  };

  /*--------------------------------------
	Utility Functions
  ---------------------------------------*/

  function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*--------------------------------------
	Event Handlers
  ---------------------------------------*/

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPrint = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Generating Environmental Impact Page...');
      const impact = calculateEnvironmentalImpact(totalUnits);
      
      // Create and setup the PDF
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true
      });
      
      // Get page dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      // First page - Environmental Impact
      const firstPageDiv = document.createElement('div');
      firstPageDiv.appendChild(createHeaderSection(impact));
      document.body.appendChild(firstPageDiv);
      
      // Capture first page
      const firstPageCanvas = await html2canvas(firstPageDiv, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff'
      });
      document.body.removeChild(firstPageDiv);
      
      // Add first page to PDF
      const firstPageImage = firstPageCanvas.toDataURL('image/png', 1.0);
      const firstPageRatio = Math.min(
        pdfWidth / firstPageCanvas.width,
        pdfHeight / firstPageCanvas.height
      );
      pdf.addImage(
        firstPageImage,
        'PNG',
        0,
        0,
        firstPageCanvas.width * firstPageRatio,
        firstPageCanvas.height * firstPageRatio
      );
      
      // Update message before material types
      setLoadingMessage('Generating Material Types Chart...');
      setTabValue(1);
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Find the material types chart using a more specific selector
      const materialChartElement = document.querySelector('#tabpanel-1 .bg-card');
      if (materialChartElement) {
        pdf.addPage();
        
        pdf.setFontSize(32);
        pdf.text('Material Types', pdfWidth / 2, 20, { align: 'center' });
        
        const chartCanvas = await html2canvas(materialChartElement, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });
        
        const chartImage = chartCanvas.toDataURL('image/png', 1.0);
        const chartRatio = Math.min(
          (pdfWidth - 20) / chartCanvas.width,
          (pdfHeight - 40) / chartCanvas.height
        );
        
        pdf.addImage(
          chartImage,
          'PNG',
          10,
          30,
          chartCanvas.width * chartRatio,
          chartCanvas.height * chartRatio
        );
      }

      // Update message before daily stats
      setLoadingMessage('Generating Daily Statistics Chart...');
      setTabValue(2);
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Find the daily stats chart using a more specific selector
      const dailyStatsElement = document.querySelector('#tabpanel-2 .bg-card');
      if (dailyStatsElement) {
        pdf.addPage();
        
        pdf.setFontSize(32);
        pdf.text('Daily Statistics', pdfWidth / 2, 20, { align: 'center' });
        
        const dailyStatsCanvas = await html2canvas(dailyStatsElement, {
          scale: 3,
      useCORS: true,
      logging: false,
      backgroundColor: '#ffffff',
          width: dailyStatsElement.offsetWidth,
          height: dailyStatsElement.offsetHeight,
        });
        
        const dailyStatsImage = dailyStatsCanvas.toDataURL('image/png', 1.0);
        
        // Use full page width with minimal margins
        const margin = 5; // 5mm margin on each side
        const imageWidth = pdfWidth - (margin * 2);
        const imageHeight = (dailyStatsCanvas.height / dailyStatsCanvas.width) * imageWidth;
        
        // If height would exceed page, scale down proportionally
        const maxHeight = pdfHeight - 40; // 40mm for title and margins
        const finalHeight = Math.min(imageHeight, maxHeight);
        const finalWidth = finalHeight * (dailyStatsCanvas.width / dailyStatsCanvas.height);
        
        // Center horizontally
        const xPosition = (pdfWidth - finalWidth) / 2;
        
        pdf.addImage(
          dailyStatsImage,
          'PNG',
          xPosition,
          30, // Fixed top position
          finalWidth,
          finalHeight
        );
      }
      
      // Final save message
      setLoadingMessage('Saving PDF...');
      setTabValue(0);
      
      // Save the PDF
      pdf.save('analytics_report.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleChangeTab = (event, newValue) => {
    console.log('=== Tab Change ===');
    console.log('New tab value:', newValue);
    // For mobile view, the event is the Select's onChange event
    if (event === null) {
      setTabValue(newValue);
      return;
    }
    // For desktop view, the event is the Tabs' onChange event
    setTabValue(newValue);
  };

  const handleChangeDate = (ranges) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    const adjustedEndDate = newEndDate > maxDate ? maxDate : newEndDate;
    setStartDate(newStartDate);
    setEndDate(adjustedEndDate);
  };

  const handleClickOpenDrawer = () => {
    // setCustomer(customer); // Set the customer to be edited
    setDrawerOpen(true); // Open the drawer
  };

  const handleDeletePropertyFilter = (id) => {
    setFilterReports((prevState) => ({
      ...prevState,
      propertyFilter: prevState.propertyFilter.filter((item) => item !== id),
    }));
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    if (user) {
      fetchProperties();
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterReports]);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const fetchProperties = async () => {
    try {
      const data = await req("GET", "has_access_to_properties");
      const filterProperties = data.filter((property) =>
        property?.location_name?.trim()
      );
      setPropertyOptions(filterProperties);
      
      // Pre-select all properties
      setFilterReports(prev => ({
        ...prev,
        propertyFilter: filterProperties.map(prop => prop.location_id)
      }));
    } catch (error) {
      console.error("Error fetching properties:", error);
      setPropertyOptions([]);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      // Format the start and end dates
      let formattedStart = encodeURIComponent(
        startDate.toISOString().split("T")[0]
      );
      let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);

      // Base URL
      let url = `customer/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`;

      // Check for selected properties in filterReports
      if (
        filterReports.propertyFilter &&
        filterReports.propertyFilter.length > 0
      ) {
        // Extract location_id values and join them into a comma-separated string
        const locationIds = filterReports.propertyFilter.join(",");

        // Add the locationsFilter parameter to the URL
        url += `&locationsFilter=${locationIds}`;
      }

      // Fetch data
      const stats = await req("GET", url);

      // Process stats and update state
      const units = calculateTotalUnits(stats || []);
      setTotalUnits(units);
      setMaterialStats(stats || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTotalUnits(0);
      setMaterialStats([]);
    }
  }, [startDate, endDate, filterReports]);

  // --------------------------------------------------------------

  if (!user) {
    return <div>Loading...</div>;
  }

  // --------------------------------------------------------------

  // Move these helper functions inside the component
  const createSection = (content, styles = '') => {
    const section = document.createElement('div');
    section.style.cssText = `
      background-color: white;
      padding: 24px;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      margin-bottom: 16px;
      width: 100%;
      ${styles}
    `;
    section.innerHTML = content;
    return section;
  };

  const getIconSvg = (iconType) => {
    switch(iconType) {
      case 'leaf':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#22c55e" style="display: block;">
          <path d="M6.05 8.05a7.001 7.001 0 0 0-.02 9.88c1.47-3.4 4.09-6.24 7.36-7.93A15.952 15.952 0 0 0 8 19.32c2.79 1.74 6.21 2.23 9.41 1.14C16.26 13.63 8.15 5.53 1.32 4.38c-.91 3.2-.42 6.62 1.32 9.41a15.952 15.952 0 0 0 9.32-4.61c-1.69 3.27-4.53 5.89-7.93 7.36z"/>
        </svg>`;
      case 'water':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#0ea5e9" style="display: block;">
          <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"/>
        </svg>`;
      case 'trash':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#16a34a" style="display: block;">
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
        </svg>`;
      case 'energy':
        return `<svg width="32" height="32" viewBox="0 0 24 24" fill="#f59e0b" style="display: block;">
          <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"/>
        </svg>`;
    }
  };

  const createPanel = (icon, title, value, subtitle, valueColor, highlightColor) => `
    <div style="
      background-color: white;
      border-radius: 16px;
      padding: 32px;
      text-align: center;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      border: 1px solid #e2e8f0;
    ">
      <div style="margin-bottom: 24px; display: flex; justify-content: center;">
        <div style="width: 48px; height: 48px; display: flex; align-items: center; justify-content: center;">
          ${getIconSvg(icon)}
        </div>
      </div>
      <div style="font-size: 20px; color: #1e293b; margin-bottom: 24px;">
        ${title}
      </div>
      <div style="font-size: 36px; color: ${valueColor}; margin-bottom: 16px; font-weight: 500;">
        ${value}
      </div>
      <div style="color: #64748b; font-size: 16px; line-height: 1.5;">
        ${subtitle}
      </div>
    </div>
  `;

  const createHeaderSection = (impact) => {
    let properties = 'All Properties';
    if (filterReports.propertyFilter.length > 0) {
      properties = 'Properties included: ' + filterReports.propertyFilter
        .map(id => propertyOptions.find(p => p.location_id === id)?.location_name)
        .join(', ');
    }
    
    return createSection(`
      <h1 style="font-size: 48px; font-weight: 600; margin-bottom: 16px; text-align: center; color: #1e293b;">Environmental Impact</h1>
      <p style="color: #64748b; margin: 0; text-align: center;">
        ${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}
      </p>
      <p style="color: #64748b; margin: 0; text-align: center; margin-bottom: 48px;">
        ${properties}
      </p>
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px;">
        ${createPanel(
          'leaf',
          'Carbon emissions avoided',
          `${formatLargeNumber(impact.carbon)} Kg of CO2`,
          `That's the same amount that a car would emit by driving for <span style="color: #22c55e; font-weight: 500;">${formatLargeNumber(impact.drivingDistance)} kilometers</span>`,
          '#22c55e',
          '#22c55e'
        )}
        ${createPanel(
          'water',
          'Water savings',
          `${formatLargeNumber(impact.water)} litres`,
          `That's the equivalent of <span style="color: #0ea5e9; font-weight: 500;">${formatLargeNumber(impact.showerMinutes)} minutes</span> of showering`,
          '#0ea5e9',
          '#0ea5e9'
        )}
        ${createPanel(
          'trash',
          'Landfill savings',
          `${formatLargeNumber(impact.landfill)} Kilograms`,
          `You've saved <span style="color: #16a34a; font-weight: 500;">${formatLargeNumber(impact.landfill)} kgs</span> of material from going to landfill`,
          '#16a34a',
          '#16a34a'
        )}
        ${createPanel(
          'energy',
          'Energy savings',
          `${formatLargeNumber(impact.energy)} Megajoules`,
          `That's enough energy to power your TV for <span style="color: #f59e0b; font-weight: 500;">${formatLargeNumber(impact.tvHours)} hours</span>`,
          '#f59e0b',
          '#f59e0b'
        )}
      </div>
    `);
  };

  return (
    <>
      {propertyOptions.length > 1 && (
        <>
          {console.log("Drawer Active")}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "600px",
              },
            }}
          >
            <FilterReports
              closeDrawer={closeDrawer}
              filterReports={filterReports}
              setFilterReports={setFilterReports}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              propertyOptions={propertyOptions}
            />
          </Drawer>
        </>
      )}
      <div id="home" className="flex flex-auto flex-col relative">
        <div className="flex w-full min-w-0 flex-col sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="flex-auto relative sm:overflow-hidden sm:h-full">
            <div className="relative h-full overflow-auto">
              <div className="absolute bg-blue-gradient w-full -z-1 h-60"></div>
              <div className="flex flex-auto w-full justify-center p-6 md:p-8 pt-0 md:pt-0">
                <div className="flex w-full flex-auto flex-col max-w-6xl">
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="pb-6 md:pb-8"
                  >
                    <Grid item sm={12} md={6} sx={{ order: { md: 2 } }}>
                      <img
                        src={logoUrl}
                        alt="TOMRA Sustainability Hub"
                        className="w-100 max-w-60 mx-auto"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={6}
                      className="text-white"
                      sx={{ order: { md: 1 } }}
                    >
                      <h1 className="text-5xl font-extrabold leading-none">
                        Welcome {user.accountName || ""}
                      </h1>
                      <p className="mt-2">
                        <strong>Account Number </strong>
                        {user.accountId || ""}
                      </p>
                    </Grid>
                  </Grid>
                  <Box className="flex flex-auto flex-col bg-white w-full relative p-6 md:p-8 lg:p-12 sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
                    <Grid container spacing={2} alignItems="top">
                      <Grid item xs={12} md={6}>
                        <div className="text-2xl font-light">Property:</div>
                        <div className="flex flex-auto flex-row items-center">
                          <div className="text-3xl font-medium leading-none tracking-tight">
                            {propertyOptions && propertyOptions.length > 0
                              ? propertyOptions.length === 1
                                ? propertyOptions[0].location_name
                                : `Selected Properties (${filterReports.propertyFilter.length}/${propertyOptions.length})`
                              : "No properties found"}
                          </div>
                          {propertyOptions && propertyOptions.length > 1 && (
                            <Button
                              variant="outlined"
                              className="mdc-button mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                              disableElevation
                              onClick={handleClickOpenDrawer}
                            >
                              Manage Properties
                            </Button>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className="mt-5 md:mt-0 border-t md:border-none"
                      >
                        <div className="text-2xl font-light">
                          Reporting period:
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                          <Button
                            variant="outlined"
                            className="justify-between w-full md:w-auto"
                            endIcon={<CalendarTodayIcon />}
                            onClick={handleOpenPopover}
                            sx={{
                              borderRadius: '8px',
                              borderColor: 'rgba(0, 0, 0, 0.23)',
                              color: 'rgba(0, 0, 0, 0.87)',
                              backgroundColor: 'transparent',
                              padding: '6px 16px',
                              boxShadow: 'none',
                              textTransform: 'none',
                              '&:hover': {
                                borderColor: 'rgba(0, 0, 0, 0.87)',
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                              }
                            }}
                          >
                            {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                          </Button>
                          <Button
                            variant="outlined"
                            className="mdc-button mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                            startIcon={isLoading ? <CircularProgress size={20} /> : <PrintIcon />}
                            onClick={() => handleClickPrint()}
                            disabled={isLoading}
                            disableElevation
                            sx={{
                              borderRadius: '8px',
                              borderColor: '#e0e0e0',
                              color: '#1976d2',
                              backgroundColor: 'transparent',
                              padding: '6px 16px',
                              boxShadow: 'none',
                              textTransform: 'none',
                              width: '100%',
                              '@media (min-width: 768px)': {
                                width: 'auto'
                              },
                              '&:hover': {
                                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                borderColor: '#1976d2'
                              }
                            }}
                          >
                            {isLoading ? 'Generating PDF...' : 'Print PDF'}
                          </Button>
                          <Popover
                            id={dateRangePopoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <DateRange
                              ranges={[selectionRange]}
                              onChange={handleChangeDate}
                              moveRangeOnFirstSelection={false}
                              maxDate={maxDate}
                            />
                          </Popover>
                        </div>
                        <div className="font-light text-md mt-2">
                          * Data is current up to 7 days ago.
                        </div>
                      </Grid>
                    </Grid>
                    {Object.values(filterReports).some(
                      (filterArray) =>
                        filterArray.length >= 2 &&
                        filterArray.length !== propertyOptions.length
                    ) && (
                      <Box className="flex flex-auto flex-col mt-6 md:mt-8 lg:mt-12 pt-6 md:pt-8 lg:pt-12 border-t">
                        <div className="text-2xl font-light mb-2">
                          Selected Properties:
                        </div>
                        <div className="flex flex-auto flex-row">
                          <Stack direction="row" spacing={1}>
                            {filterReports.propertyFilter.map((locationId) => {
                              // Find the location_name corresponding to the current locationId
                              const locationName =
                                propertyOptions.find(
                                  (option) => option.location_id === locationId
                                )?.location_name || "Unknown Location"; // Lookup location_name

                              return (
                                <Chip
                                  key={locationId} // Use locationId as the key since it's unique
                                  label={locationName}
                                  variant="outlined"
                                  className="chip-large"
                                  onDelete={() =>
                                    handleDeletePropertyFilter(locationId)
                                  } // Pass locationId to the delete handler
                                />
                              );
                            })}
                          </Stack>
                        </div>
                      </Box>
                    )}
                  </Box>
                  <Box
                    className="pt-8 pb-6 md:pt-10 md:pb-8"
                    sx={{ width: "100%" }}
                  >
                    <Box
                      style={{ boxShadow: "inset 0 -1px var(--fuse-border)" }}
                    >
                      {isMobile ? (
                        <Select
                          value={tabValue}
                          onChange={(event) =>
                            handleChangeTab(null, event.target.value)
                          }
                          fullWidth
                          displayEmpty
                          variant="outlined"
                        >
                          <MenuItem value={0}>Environmental Impact</MenuItem>
                          <MenuItem value={1}>Material Types</MenuItem>
                          <MenuItem value={2}>Daily Stats</MenuItem>
                          <MenuItem value={3}>Charity Donations</MenuItem>
                          <MenuItem value={4}>Map</MenuItem>
                        </Select>
                      ) : (
                        <Tabs
                          value={tabValue}
                          onChange={handleChangeTab}
                          aria-label="responsive tabs example"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                        >
                          <Tab label="Environmental Impact" {...a11yProps(0)} />
                          <Tab label="Material Types" {...a11yProps(1)} />
                          <Tab label="Daily Stats" {...a11yProps(2)} />
                          <Tab label="Charity Donations" {...a11yProps(3)} />
                          <Tab label="Map" {...a11yProps(4)} />
                        </Tabs>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TabPanel value={tabValue} index={0}>
                      {materialStats ? (
                        <EnvironmentalStats totalUnits={totalUnits} />
                      ) : (
                        <div className="flex h-full flex-auto flex-col items-center justify-center py-12">
                          <div className="text-center text-3xl font-medium leading-tight tracking-tight">
                            No Data Available
                          </div>
                          <div className="text-secondary mt-2 text-center text-lg">
                            There is no environmental impact data for the selected date range.
                          </div>
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 py-12 shadow">
                          {materialStats && materialStats.length > 0 ? (
                            <MaterialPieChart materialStats={materialStats} />
                          ) : (
                            <div className="flex h-full flex-auto flex-col items-center justify-center py-12">
                              <div className="text-center text-3xl font-medium leading-tight tracking-tight">
                                No Data Available
                              </div>
                              <div className="text-secondary mt-2 text-center text-md pb-2">
                                There is no material type data for the selected date range.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                          {materialStats && materialStats.length > 0 ? (
                            <DepositDailyBarChart materialStats={materialStats} />
                          ) : (
                            <div className="flex h-full flex-auto flex-col items-center justify-center py-12">
                              <div className="text-center text-3xl font-medium leading-tight tracking-tight">
                                No Data Available
                              </div>
                              <div className="text-secondary mt-2 text-center text-lg">
                                There is no daily statistics data for the selected date range.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                      <div className="flex h-full flex-auto flex-col items-center justify-center">
                        <div className="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none">
                          Charity Donations
                        </div>
                        <div className="text-secondary mt-2 text-center text-lg font-medium tracking-tight md:text-xl">
                          Sorry for the inconvenience, Charity Donations should
                          be available soon!
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                      {console.log('=== Map Tab Panel ===', { tabValue, isTabActive: tabValue === 4 })}
                      <div className="flex flex-auto flex-col w-full" style={{ height: '600px' }}>
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow h-full">
                  
                          <div className="flex-1 relative">
                            <GoogleMapsLoader>
                              {console.log('=== Inside GoogleMapsLoader ===')}
                              <StatsMap
                                role="customer"
                                startDate={startDate}
                                endDate={endDate}
                                filterReports={filterReports}
                              />
                            </GoogleMapsLoader>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingOverlay open={isLoading} message={loadingMessage} />
    </>
  );
}
