import React from 'react';
import { Box, Typography, Container, Paper, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Terms and Conditions
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom align="center">
          Last Updated – 18/03/2025
        </Typography>
        
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            These Terms and Conditions ("Terms") govern your access to and use of the Portal, provided
            by TOMRA Collection Pty Ltd (ACN 111 224 018) ("TOMRA," "we," "us," or "our"). By
            accessing or using the Portal, you ("you" or "your") agree to be bound by these Terms. If you
            disagree with any part of the Terms, then you may not access the Portal.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. User Categories and Access
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Internal Users:" 
                secondary="TOMRA employees and administrators with access to manage location data, customer data, and user accounts."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="External Users:" 
                secondary="Site contacts, property managers, local government officials, and other corporate organizations with access to view and analyse data related to their linked locations."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            3. Portal Functionality
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Location Management:" 
                secondary="Internal users can add, update, and delete location information."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Customer Management:" 
                secondary="Internal users can add, update, and delete customer data and link customers to locations."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Data Visualization:" 
                secondary="External users can view container volume data, environmental impact metrics, and material type distribution for their linked locations."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Reporting:" 
                secondary="Both internal and external users can download environmental impact reports."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Account Management:" 
                secondary="Users can manage their account settings, including password changes and team member additions."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            4. User Responsibilities
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Account Security:" 
                secondary="You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Acceptable Use:" 
                secondary="You agree to use the Portal only for lawful purposes and in a manner that does not violate these Terms or any applicable laws or regulations."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            5. Intellectual Property
          </Typography>
          <Typography paragraph>
            The Portal and its entire contents, features, and functionality (including but not limited to all
            information, software, text, displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by TOMRA, its licensors, or other providers of such material
            and are protected by Australian and international copyright, trademark, patent, trade secret,
            and other intellectual property or proprietary rights laws.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Disclaimer
          </Typography>
          <Typography paragraph>
            The Portal is provided "as is" without warranty of any kind, either express or implied,
            including but not limited to the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement. TOMRA does not warrant that the Portal will be
            uninterrupted or error-free, that defects will be corrected, or that the Portal or the server that
            makes it available are free of viruses or other harmful components.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Limitation of Liability
          </Typography>
          <Typography paragraph>
            In no event will TOMRA be liable to you, or to any organisation for which you work or
            consult or report to, for any damages whatsoever, including but not limited to direct, indirect,
            special, incidental, consequential, or punitive damages, arising out of or relating to your use
            of or inability to use the Portal, including (without limitation) the accuracy of, or your use of,
            any information that you access from the Portal, whether based on breach of warranty,
            contract, tort (including negligence), common law or any other cause of action, even if
            TOMRA has been advised of the possibility of such damages.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Indemnification
          </Typography>
          <Typography paragraph>
            You agree to defend, indemnify, and hold harmless TOMRA, its affiliates, licensors, and
            service providers, and their respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages,
            judgments, awards, losses, costs, expenses, or fees (including reasonable legal costs) arising
            out of or relating to your breach of these Terms or your use of the Portal, including but not
            limited to your user contributions, any use of the Portal's content, services, and products other
            than as expressly authorized in these Terms, or your use of any information obtained from the
            Portal. You acknowledge and agree that TOMRA may enforce this indemnity as agent for its
            affiliates, licensors and service providers, and their respective officers, directors, employees,
            contractors, agents, licensors, suppliers, successors and assigns.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms shall be governed by and construed in accordance with the laws of New South
            Wales, Australia, without regard to its conflict of law provisions.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Dispute Resolution
          </Typography>
          <Typography paragraph>
            Any dispute arising out of or relating to these Terms or the Portal shall be settled by
            arbitration in accordance with the rules of the Australian Centre for International Commercial
            Arbitration (ACICA). The arbitration shall be held in Sydney, Australia, and the language of
            the arbitration shall be English.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Entire Agreement
          </Typography>
          <Typography paragraph>
            These Terms constitute the entire agreement between you and TOMRA relating to either your
            use of or the functionality and operation of the Portal and supersede all prior or
            contemporaneous communications and proposals, whether electronic, oral, or written,
            between you and TOMRA with respect to the Portal.
          </Typography>

          <Typography variant="h6" gutterBottom>
            12. Waiver
          </Typography>
          <Typography paragraph>
            A failure to exercise or a delay in exercising any right, power or remedy under these Terms
            does not operate as a waiver. A single or partial exercise or waiver of the exercise of any
            right, power or remedy does not preclude any other or further exercise of that or any other
            right, power or remedy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            13. Severability
          </Typography>
          <Typography paragraph>
            If any provision of these Terms is held to be invalid, illegal, or unenforceable for any reason,
            such provision shall be deemed severable from the other provisions of these Terms and shall
            not affect the remaining provisions of the Terms, which will continue in full force and effect.
          </Typography>

          <Typography variant="h6" gutterBottom>
            14. Changes to Terms
          </Typography>
          <Typography paragraph>
            TOMRA reserves the right to modify these Terms at any time in its sole discretion. If we
            make material changes to these Terms, we will post the updated Terms on the Portal and
            update the "Effective Date" at the top of this page. Your continued use of the Portal following
            the posting of revised Terms means that you accept and agree to the changes.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15. Stop or suspension of Portal
          </Typography>
          <Typography paragraph>
            TOMRA reserves the right to, at any time and without compensation to anyone, stop or
            suspend the operation of the Portal.
          </Typography>

          <Typography variant="h6" gutterBottom>
            16. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about these Terms, please contact us at:
          </Typography>
          <Typography paragraph>
            TOMRA Collection Pty Ltd<br />
            Lvl 5, Building A, 207 Pacific Highway<br />
            St Leonards NSW 2065<br />
            Australia<br />
            Email: help.tca@tomra.com<br />
            Website: www.mytomra.com.au
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions; 