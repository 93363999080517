import React from 'react';
import { Box, Typography, Container, Paper, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
            TOMRA Sustainability Hub – Privacy Policy
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Last Updated – 18/03/2025
          </Typography>

          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            1. Introduction
          </Typography>
          <Typography paragraph>
            TOMRA Collection Pty Ltd (ACN 111 224 018) ("we," "us," or "our") is committed to
            protecting the privacy of our users ("you" or "your"). This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you use our portal.
          </Typography>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            2. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect the following types of information:
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Personal Information:" 
                secondary="This includes your name and email address." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Automatically Collected Information:" 
                secondary="We automatically collect certain information when you use our portal, such as your IP address, browser type, operating system, device information, and usage data." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Cookies:" 
                secondary="We use cookies and similar technologies to enhance your experience on our portal. You can control cookie settings through your browser preferences." 
              />
            </ListItem>
          </List>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            3. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use your information for the following purposes:
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Account Management:" 
                secondary="To create and manage your account on our portal." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Customer Support:" 
                secondary="To provide you with assistance and respond to your inquiries." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Analytics:" 
                secondary="To analyse portal usage and improve our services." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Communication:" 
                secondary="To send you important updates and announcements." 
              />
            </ListItem>
          </List>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            4. Disclosure of Your Information
          </Typography>
          <Typography paragraph>
            We may disclose your information to the following third parties:
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Service Providers:" 
                secondary="We may share your information with trusted service providers who assist us with portal operations, such as data storage and analytics." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Legal Authorities:" 
                secondary="We may disclose your information to comply with applicable laws and regulations or to respond to legal requests." 
              />
            </ListItem>
          </List>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            5. Disclosure of personal information to overseas recipients
          </Typography>
          <Typography paragraph>
            We are unlikely to disclose the personal information that we collect and hold about you to
            third parties who are not in Australia.
          </Typography>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            6. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate security measures to protect your information from unauthorized
            access, use, or disclosure. These measures include data encryption and firewalls.
          </Typography>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            7. Data Retention
          </Typography>
          <Typography paragraph>
            We retain your information for as long as necessary to fulfill the purposes outlined in this
            Privacy Policy, unless a longer retention period is required or permitted by law.
          </Typography>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            8. Your Rights
          </Typography>
          <Typography paragraph>
            You have the following rights regarding your personal information:
          </Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Access and Update:" 
                secondary="You can access and update your personal information through your account settings." 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Contact Us:" 
                secondary="You can contact us at help.tca@tomra.com to exercise your privacy rights or inquire about this Privacy Policy." 
              />
            </ListItem>
          </List>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            9. Updates to this Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on our
            portal, and the revised policy will be effective immediately upon posting.
          </Typography>
          
          <Typography variant="h6" component="h3" sx={{ mt: 3, fontWeight: 'bold' }}>
            10. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </Typography>
          <Typography paragraph>
            TOMRA Collection Pty Ltd<br />
            Level 5, Building A/207 Pacific Hwy, St Leonards NSW 2065<br />
            Email: <a href="mailto:help.tca@tomra.com">help.tca@tomra.com</a>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy; 