import React, { useState, useEffect } from "react";
import {
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  InputAdornment,
  Button,
  Box,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import CSVIcon from "@mui/icons-material/Description"; // Use a different icon if preferred
import ExcelIcon from "@mui/icons-material/GridOn"; // Use a different icon if preferred

import { req } from "../utils/api";
import exportToCsv from "json-to-csv-export";
import * as XLSX from "xlsx";

const DataTable = ({
  url,
  columns,
  paginationType = "server", // 'client' or 'server'
  initialPageSize = 10,
  searchEnabled = true,
  additionalParams = {},
  refresh, // New prop for external refresh trigger
  rowClickAction, // New prop for row click action
  exportEnabled = true, // New prop to control export UI visibility
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [totalRows, setTotalRows] = useState(0);
  const [sortModel, setSortModel] = useState({ field: "", order: "" });
  const [searchText, setSearchText] = useState("");
  const [exportType, setExportType] = useState("csv");

  const fetchData = async () => {
    setLoading(true);

    const parameters = {
      page: page + 1,
      pageSize,
      sortField: sortModel.field,
      sortOrder: sortModel.order,
      searchText,
      ...additionalParams,
    };

    try {
      const result = await req("GET", url, parameters);
      if (!result.success) {
        throw new Error(result.message || "Failed to fetch data.");
      }

      setData(result.data.rows);
      setTotalRows(result.data.total);
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paginationType === "server") {
      fetchData();
    }
  }, [page, pageSize, sortModel, searchText, refresh]); // Include `refresh` in the dependency array

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const handleSortChange = (field) => {
    setSortModel((prev) => ({
      field,
      order: prev.field === field && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setPage(0); // Reset to the first page
  };

  const handleExportChange = (event) => {
    setExportType(event.target.value);
    handleExport();
  };

  const handleExport = () => {
    if (exportType === "csv") {
      exportToCSV();
    } else if (exportType === "excel") {
      exportToExcel();
    }
  };

  const handleRefresh = () => {
    fetchData();
  };

  const clientSideData = () => {
    if (paginationType === "client") {
      let filteredData = data;
      if (searchText) {
        filteredData = filteredData.filter((row) =>
          columns.some((column) =>
            row[column.field]
              ?.toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
        );
      }
      if (sortModel.field) {
        filteredData.sort((a, b) => {
          const order = sortModel.order === "asc" ? 1 : -1;
          return (
            a[sortModel.field]
              ?.toString()
              .localeCompare(b[sortModel.field]?.toString()) * order
          );
        });
      }
      setTotalRows(filteredData.length);
      return filteredData.slice(page * pageSize, (page + 1) * pageSize);
    }
    return data;
  };

  const exportToCSV = () => {
    const exportData = data.map((row) => {
      const obj = {};
      columns.forEach((col) => {
        obj[col.headerName] = col.formatter
          ? col.formatter(row[col.field], row, row.index)
          : row[col.field];
      });
      return obj;
    });

    exportToCsv({ data: exportData, filename: "data_export", delimiter: "," });
  };

  const exportToExcel = () => {
    const exportData = data.map((row) => {
      const obj = {};
      columns.forEach((col) => {
        obj[col.headerName] = col.formatter
          ? col.formatter(row[col.field], row, row.index)
          : row[col.field];
      });
      return obj;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "data_export.xlsx");
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        sx={{ mb: 2 }}
      >
        <TextField
          variant="outlined"
          className="w-full min-w-50"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px", // Apply the rounding here
            },
          }}
        />
        <Box
          display="flex"
          flexDirection="row"
          alignItems={{ xs: "stretch", lg: "center" }}
          gap="1rem"
          sx={{ width: { xs: "100%", lg: "auto" } }}
        >
          {exportEnabled && (
            <Select
              value={"download"}
              onChange={handleExportChange}
              variant="outlined"
              size="small"
              sx={{
                flex: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "20px",
                },
              }}
            >
              <MenuItem value="download">
                <DownloadIcon /> Export
              </MenuItem>
              <MenuItem value="csv">
                <CSVIcon /> CSV
              </MenuItem>
              <MenuItem value="excel">
                <ExcelIcon /> Excel
              </MenuItem>
            </Select>
          )}
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  <TableSortLabel
                    active={sortModel.field === column.field}
                    direction={sortModel.order}
                    onClick={() => handleSortChange(column.field)}
                  >
                    {column.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              clientSideData().map((row, index) => (
                <TableRow
                  key={index}
                  {...(rowClickAction && {
                    onClick: () => rowClickAction(row), // Pass the entire row data to the parent function
                    hover: true, // Add hover effect for better UX
                    style: { cursor: "pointer" }, // Make it clear that the row is clickable
                  })}
                >
                  {columns.map((column) => (
                    <TableCell key={column.field}>
                      {column.formatter
                        ? column.formatter(row[column.field], row, index)
                        : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </>
  );
};

export default DataTable;
