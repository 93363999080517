import React, { useRef, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CircularProgress, Typography, Box } from "@mui/material";

// Registering components
ChartJS.register(ArcElement, Tooltip, Legend);

export function MaterialPieChart({ materialStats, isLoading }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.canvas.style.width = "100%";
      chart.canvas.style.height = "auto";
      chart.canvas.style.aspectRatio = "1 / 1";
      chart.resize();
    }
  }, []);

  // Aggregate data by material type
  const aggregateDataByMaterial = (data) => {
    const aggregatedData = {};
    try {
      if (!data) {
        return aggregatedData;
      }
    data.forEach((stat) => {
      if (!aggregatedData[stat.material]) {
        aggregatedData[stat.material] = 0;
      }
      aggregatedData[stat.material] += parseInt(stat.total_units, 10); // Aggregate by total_units
    });

      return aggregatedData;
    } catch (error) {
      console.error("Error aggregating data:", error);
      return aggregatedData;
    }
  };

  const aggregatedData = aggregateDataByMaterial(materialStats);

  // Predefined color palette with distinct but professional colors
  const colorPalette = [
    "#2E74B5", // Strong blue
    "#44AB44", // Vibrant green
    "#ED553B", // Coral red
    "#FFB400", // Golden yellow
    "#8B44B5", // Purple
    "#00A3B5", // Teal
    "#FF6B6B", // Light red
    "#4CAF50", // Material green
    "#3F51B5", // Indigo
    "#FF9800", // Orange
    "#9C27B0", // Deep purple
    "#009688", // Teal
    "#E91E63", // Pink
    "#607D8B", // Blue grey
    "#795548", // Brown
    "#F44336", // Material red
    "#2196F3", // Material blue
    "#FFC107", // Amber
    "#00BCD4", // Cyan
    "#673AB7"  // Deep purple
  ];

  // Prepare data for the Pie chart
  const pieData = {
    labels: Object.keys(aggregatedData), // Material types
    datasets: [
      {
        data: Object.values(aggregatedData), // Corresponding total_units
        backgroundColor: colorPalette.slice(
          0,
          Object.keys(aggregatedData).length
        ), // Use colors from the palette
      },
    ],
  };

  const hasData = materialStats && materialStats.length > 0;

  if (isLoading) {
    return (
      <Box
        className="material-pie-chart-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!hasData) {
    return (
      <Box
        className="material-pie-chart-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <Typography variant="h6" color="text.secondary">
          No data available
        </Typography>
      </Box>
    );
  }

  return (
    <div className="material-pie-chart-container">
      <Pie
        ref={chartRef}
        data={pieData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              align: "center",
              labels: {
                boxWidth: 15,
                padding: 10,
                font: {
                  size: 12,
                },
              },
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let label = tooltipItem.label || "";
                  if (label) {
                    label += ": ";
                  }
                  const value = tooltipItem.raw || 0;
                  label += value.toLocaleString();
                  return label;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

// Update the style block
const styles = `
  <style>
    .material-pie-chart-container {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
    }
    .material-pie-chart-container canvas {
		height: calc(100vh - 425px) !important;
		margin: 0 auto;
		max-height: 500px !important;
		max-width: 889px !important;
		width: 100%;
    }
    @media (max-width: 768px) {
      .material-pie-chart-container canvas {
        max-width: 300px !important;
        max-height: 300px !important;
      }
    }
  </style>
`;

// Inject the styles into the document head
if (typeof document !== "undefined") {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styles;
  document.head.appendChild(styleElement);
}
