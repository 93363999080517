// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { Box, Button, Chip, Drawer, Grid, Stack } from "@mui/material";

// 3. Component Imports:
import { CreateEditLocation } from "../../components/locations/CreateEditLocation";
import { SnackbarNotification } from "../../components/SnackbarNotification";
import DataTable from "../../components/DataTable";

// 6. Utility Functions or Constants:
import { req } from "utils/api";
import { useAuth } from "../../contexts/AuthContext";

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";

export function AdminLocations() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const { user } = useAuth();
  const [refreshCounter, setRefreshCounter] = useState(0); // State to trigger refresh
  const [dataDrawerOpen, setDataDrawerOpen] = useState(false);
  const [location, setLocation] = useState({}); // State to hold the location being edited
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isDownloading, setIsDownloading] = useState(false);

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const linkAccountsFormatter = (value, row) => {
    const linkedAccounts = {};

    row.accounts_with_access.forEach((item) => {
      if (
        item.location_relationship !== null &&
        !linkedAccounts[item.location_relationship]
      ) {
        linkedAccounts[item.location_relationship] = 1;
      } else if (item.location_relationship !== null) {
        linkedAccounts[item.location_relationship]++;
      }
    });

    return (
      <Stack direction="row" spacing={1}>
        {Object.entries(linkedAccounts).map(([type, count]) => (
          <Chip
            key={type}
            label={`${type}: ${count}`}
            size="small"
            color="primary"
            variant="outlined"
          />
        ))}
      </Stack>
    );
  };

  const columns = [
    { field: "location_g_id", headerName: "ID" },
    { field: "location_name", headerName: "Name" },
    { field: "address", headerName: "Address" },
    {
      field: "accounts_with_access",
      headerName: "Linked Accounts",
      formatter: linkAccountsFormatter,
    },
  ];

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const closeDataDrawer = () => {
    setDataDrawerOpen(false);
  };

  const reloadTable = () => {
    // Trigger a refresh by incrementing the counter
    setRefreshCounter((prev) => prev + 1);
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleLocationCreated = (location) => {
    reloadTable();
    closeDataDrawer();
  };

  const handleLocationUpdated = () => {
    reloadTable();
    closeDataDrawer();
  };

  const handleClickUpdateLocation = (location) => {
    setLocation(location); // Set the location to be edited
    setDataDrawerOpen(true); // Open the drawer
  };

  const handleClickCreateLocation = () => {
    setLocation({}); // Clear the location to indicate a new entry
    setDataDrawerOpen(true); // Open the drawer
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDownloadAllLocations = async () => {
    setIsDownloading(true);
    try {
      // Get the current token from local storage
      const auth_data = localStorage.getItem("_tomra_auth");
      let token = '';
      if (auth_data) {
        const { token: parsedToken } = JSON.parse(auth_data);
        token = parsedToken;
      }

      // Create a fetch request with the appropriate headers
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}admin/download_all_locations`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the filename from the Content-Disposition header if available
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'locations.csv';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      // Convert the response to a blob
      const blob = await response.blob();
      
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      
      // Append to the document and trigger the download
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      setSnackbarMessage("Locations downloaded successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Failed to download locations:", error);
      setSnackbarMessage("Failed to download locations.");
      setSnackbarSeverity("error");
    } finally {
      setIsDownloading(false);
      setOpenSnackbar(true);
    }
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "Manage Locations";
  }, []);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const createLocation = async (postData) => {
    try {
      const response = await req("post", "admin/create_location", postData);

      if (response.success === true) {
        setSnackbarMessage("Location created successfully.");
        setSnackbarSeverity("success");
        reloadTable(); // Reload locations after deletion
        closeDataDrawer(); // Close the drawer after deletion
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to create new location:", error);
      setSnackbarMessage(`Failed to create new location.`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const updateLocation = async (postData) => {
    try {
      const response = await req("post", "admin/update_location", postData);

      if (response.success === true) {
        setSnackbarMessage("Location updated successfully.");
        setSnackbarSeverity("success");
        reloadTable(); // Reload locations after deletion
        closeDataDrawer(); // Close the drawer after deletion
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to update location:", error);
      setSnackbarMessage(`Failed to update location.`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteLocation = async (location_id) => {
    try {
      await req("post", "admin/delete_location", { id: location_id });
      setSnackbarMessage("Location deleted successfully.");
      setSnackbarSeverity("success");

      reloadTable(); // Reload locations after deletion
      closeDataDrawer(); // Close the drawer after deletion
    } catch (error) {
      console.error("Failed to delete location:", error);
      setSnackbarMessage(`Failed to delete account: ${error}`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const createLinkedAccount = async (data) => {
    try {
      const response = await req("post", "admin/add_account_to_location", data);

      if (response.success === true) {
        setSnackbarMessage("Successfully linked account to location.");
        setSnackbarSeverity("success");
        reloadTable(); // Reload locations after deletion
        return response;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to link account to location:", error);
      setSnackbarMessage("Failed to link account to location.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteLinkedAccount = async (data) => {
    try {
      const response = await req(
        "post",
        "admin/remove_account_from_location",
        data
      );

      if (response.success === true) {
        setSnackbarMessage(
          "Linked Account removed from location successfully."
        );
        setSnackbarSeverity("success");
        reloadTable(); // Reload locations after deletion
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error(
        "Failed to remove the linked account from the location:",
        error
      );
      setSnackbarMessage(
        "Failed to remove the linked account from the location."
      );
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={dataDrawerOpen}
        onClose={closeDataDrawer}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px",
          },
        }}
      >
        <CreateEditLocation
          closeDataDrawer={closeDataDrawer}
          reloadTable={reloadTable}
          location={location} // Pass the location to the component
          handleLocationCreated={handleLocationCreated}
          handleLocationUpdated={handleLocationUpdated}
          createLocation={createLocation}
          updateLocation={updateLocation}
          deleteLocation={deleteLocation}
          createLinkedAccount={createLinkedAccount}
          deleteLinkedAccount={deleteLinkedAccount}
		  userAccountType={user?.accountType}
        />
      </Drawer>
      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, boxShadow: 1 }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                Locations
              </h1>
              <div className="subtitle">Review &amp; Manage Locations</div>
            </Grid>
            <Grid item xs={12} sm={6} className="text-right">
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
                <Button
                  className="mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadAllLocations}
                  disabled={isDownloading}
                  disableElevation
                  sx={{ width: { xs: "100%", lg: "auto" } }}
                >
                  {isDownloading ? "Downloading..." : "Download All Locations"}
                </Button>
                {user?.accountType === 'admin' && (
                  <Button
                    className="mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleClickCreateLocation}
                    disableElevation
                    sx={{ width: { xs: "100%", lg: "auto" } }}
                  >
                    Create Location
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataTable
              url="admin/get_locations"
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              refresh={refreshCounter}
              rowClickAction={handleClickUpdateLocation}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}
