import React, { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../features/authentication/hooks/useAuth';

const ProtectedRoute = () => {
    const { isAuthenticated, loading, checkAndRefreshToken, authState } = useAuth();
    const location = useLocation();

    useEffect(() => {
        //console.log("ProtectedRoute mounted, checking token", isAuthenticated);
        checkAndRefreshToken();
       
    }, []);

    if (loading) {
        // You can render a loading spinner or placeholder here
        return <div>Loading...</div>;
    }
    //console.log("ProtectedRoute authState:", authState);
    return (
        isAuthenticated
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default ProtectedRoute;