import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, eachDayOfInterval } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export function DepositDailyBarChart({ materialStats }) {
  // Aggregate data by date and material type
  const aggregateDataByDateAndMaterial = (data) => {
    const aggregatedData = {};
    try {
      if(!data) {
        return aggregatedData;
      }
    data.forEach(stat => {
      const date = format(new Date(stat.deposit_date), 'yyyy-MM-dd');
      if (!aggregatedData[date]) {
        aggregatedData[date] = {};
      }
      if (!aggregatedData[date][stat.material]) {
        aggregatedData[date][stat.material] = 0;
      }
      aggregatedData[date][stat.material] += parseInt(stat.total_units, 10); // Aggregate by total_units
    });

      return aggregatedData;
    } catch (error) {
      console.error("Error aggregating data:", error);
      return aggregatedData;
    }
  };

  const aggregatedData = aggregateDataByDateAndMaterial(materialStats);

  // Extract unique materials
  if(!materialStats || materialStats.length === 0) {
    return <div>No data available</div>;
  }
  const materials = Array.from(new Set(materialStats.map(stat => stat.material)));

  // Extract all dates in the range
  const allDates = eachDayOfInterval({
    start: new Date(Math.min(...materialStats.map(stat => new Date(stat.deposit_date)))),
    end: new Date(Math.max(...materialStats.map(stat => new Date(stat.deposit_date)))),
  }).map(date => format(date, 'yyyy-MM-dd'));

  // Predefined color palette with distinct but professional colors
  const colorPalette = [
    '#2E74B5', // Strong blue
    '#44AB44', // Vibrant green
    '#ED553B', // Coral red
    '#FFB400', // Golden yellow
    '#8B44B5', // Purple
    '#00A3B5', // Teal
    '#FF6B6B', // Light red
    '#4CAF50', // Material green
    '#3F51B5', // Indigo
    '#FF9800', // Orange
    '#9C27B0', // Deep purple
    '#009688', // Teal
    '#E91E63', // Pink
    '#607D8B', // Blue grey
    '#795548', // Brown
    '#F44336', // Material red
    '#2196F3', // Material blue
    '#FFC107', // Amber
    '#00BCD4', // Cyan
    '#673AB7'  // Deep purple
  ];

  // Prepare datasets
  const datasets = materials.map((material, index) => {
    return {
      label: material,
      data: allDates.map(date => 
        aggregatedData[date] && aggregatedData[date][material] ? aggregatedData[date][material] : 0
      ),
      backgroundColor: colorPalette[index % colorPalette.length],
    };
  });

  const barData = {
    labels: allDates.map(date => new Date(date)), // Dates for the x-axis
    datasets: datasets,
  };

  return (
    <div>
      <Bar 
        data={barData} 
        options={{
          plugins: {
            legend: {
              position: 'top',
            },
          },
          scales: {
            x: {
              type: 'time', // Ensure the x-axis is time-based
              time: {
                unit: 'day', // Show data per day
                tooltipFormat: 'dd/MM/yyyy', // Tooltip date format
                displayFormats: {
                  day: 'dd/MM/yyyy', // Axis label date format
                },
              },
              stacked: true, // Enable stacking on the x-axis
              ticks: {
                source: 'labels', // Ensure ticks are aligned with the labels
                autoSkip: false, // Do not skip any dates
                minRotation: 90, // Make labels vertical
                maxRotation: 90, // Keep them consistently vertical
                padding: 10, // Add some padding
                callback: function(value) {
                  return format(new Date(value), 'dd/MM/yyyy'); // Format tick labels
                },
              },
            },
            y: {
              beginAtZero: true,
              stacked: true, // Enable stacking on the y-axis
            },
          },
        }} 
      />
    </div>
  );
}
