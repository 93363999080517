import { useState, useEffect } from 'react';
import { req} from '../../utils/api';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndConditions from '../TermsAndConditions';

import { useAuth } from '../../contexts/AuthContext';
//checks to see if the user has agreed to the terms and conditions
export function TermsAgreementModal() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  //console.log('TermsAgreementModal user', user);
  const [showModal, setShowModal] = useState(false);
  
  const handleAgree = async () => {
    //hide the modal
    setShowModal(false);
    //update the local storage
    let ls = localStorage.getItem('_tomra_auth');
    ls = JSON.parse(ls);
    ls.user.termsAgreedVersion = "1.0";
    localStorage.setItem('_tomra_auth', JSON.stringify(ls));
    //update the server
    const response = await req('POST', 'record_terms_agreement', {
      terms_agreed_version:"1.0"
    });
    //console.log('TermsAgreementModal response', response);
  };
  useEffect(() => {
    if (!user?.termsAgreedVersion) {
      setShowModal(true);
    }
  }, [user]);
  if (!showModal ||!user) return <></>;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ 
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 99999,
    }}>
      <div className="bg-white rounded-lg w-full max-w-4xl flex flex-col mx-8" style={{ maxHeight: 'calc(100vh - 64px)' }}>
        {/* Modal Header */}
        <div className="p-6 pb-4 border-b">
          <h2 className="text-xl font-bold">Terms and Conditions</h2>
        </div>

        {/* Scrollable Content Area */}
        <div className="p-6 overflow-y-auto flex-1">
          <div className="space-y-4">
            <PrivacyPolicy />
            <TermsAndConditions />
          </div>
        </div>

        {/* Modal Footer */}
        <div className="p-6 border-t bg-gray-50">
          <button 
            onClick={handleAgree}
            disabled={isLoading}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-md transition-colors disabled:opacity-50"
          >
            {isLoading ? 'Processing...' : 'I Agree to the Terms'}
          </button>
        </div>
      </div>
    </div>
    
  );
}   