import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import "../assets/css/main_v1.css";

import { NavbarAdmin } from '../components/NavbarAdmin';

import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { AccountCircleOutlined, Logout, Settings, EmailOutlined, GavelOutlined, SecurityOutlined } from '@mui/icons-material';

import UserAuth from "../features/authentication/hooks/useAuth";
import { TermsAgreementModal } from '../components/auth/TermsAgreementModal';
export function AdminLayout() {

    const navigate = useNavigate();
	
	/* User Authentication */
	const { user, logout } = UserAuth();

	const handleSignOut = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }


	/* Navigation */
	const minScreenWidth = 960;
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [isNavOpen, setIsNavOpen] = useState(window.innerWidth > minScreenWidth);
	const navigationClasses = screenWidth > minScreenWidth ? 'vertical-navigation-mode-side' : 'vertical-navigation-mode-over';

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

	const manageNav = () => {
		const e = document.querySelector("#vertical-navigation");

		if(isNavOpen) {
			e.classList.add("vertical-navigation-opened");
			e.style.visibility = "visible";
		} else {
			e.classList.remove("vertical-navigation-opened");
			e.style.visibility = "hidden";
		}
	}

    const manageOverlay = () => {
      const overlay = document.querySelector(".vertical-navigation-overlay");
      if (isNavOpen && window.innerWidth < 900) {
        if (!overlay) {
          const newOverlay = document.createElement("div");
          newOverlay.className = "vertical-navigation-overlay";
          newOverlay.addEventListener("click", () => {
            setIsNavOpen(false);
            newOverlay.remove();
          });
          document.querySelector("#layout > div").appendChild(newOverlay);
        }
      } else {
        if (overlay) {
          overlay.removeEventListener("click");
          overlay.remove();
        }
      }
    }

	useEffect(() => {
		document.body.classList.remove('auth');
		
		// Manage navigation open/close
		manageNav();

		// Manage overlay on mount and when isNavOpen changes
		manageOverlay();

		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setIsNavOpen(window.innerWidth > minScreenWidth);
		};
		
		window.addEventListener('resize', handleResize);
		
		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};

	}, [isNavOpen]);

	return (<>
		<CssBaseline />
		<div id="layout">
			<div>
				<div id="vertical-navigation" className={`dark bg-gray-900 print:hidden vertical-navigation-animations-enabled vertical-navigation-appearance-default vertical-navigation-position-left ${navigationClasses}`} style={{ visibility: 'visible' }}>
					<NavbarAdmin />
				</div>
				<div className="flex w-full min-w-0 flex-auto flex-col">
					<div className="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
						<button className="btn-mat-icon"  onClick={() => setIsNavOpen(prev => !prev)}>
							<i className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="menu">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
								</svg>
							</i>
						</button>
						<div className="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
							<Tooltip title="Account settings">
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'user-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
								>
									<Avatar sx={{ width: 32, height: 32 }}><AccountCircleOutlined /></Avatar>
								</IconButton>
							</Tooltip>
							<Menu
								anchorEl={anchorEl}
								id="user-menu"
								open={open}
								onClose={handleClose}
								onClick={handleClose}
								PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									minWidth: 220,
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							>
								<MenuItem style={{ flexDirection: "column" }}>
									<div style={{ fontSize: ".75rem" }}>Signed in as:</div>
									<div className="capitalize">{user && user.accountName ? user.accountName : 'Username'}</div>
								</MenuItem>
								<Divider />
								<MenuItem component={Link} to="/admin/settings">
									<ListItemIcon>
										<Settings fontSize="small" />
									</ListItemIcon>
									Settings
								</MenuItem>
								<MenuItem component="a" href="mailto:help.tca@tomra.com">
										<ListItemIcon>
											<EmailOutlined fontSize="small" />
										</ListItemIcon>
										Contact Us
									</MenuItem>
								<MenuItem target="_blank" component={Link} to="/terms">
									<ListItemIcon>
										<GavelOutlined fontSize="small" />
									</ListItemIcon>
									Terms & Conditions
								</MenuItem>
								<MenuItem target="_blank" component={Link} to="/privacy">
									<ListItemIcon>
										<SecurityOutlined fontSize="small" />
									</ListItemIcon>
									Privacy Policy
								</MenuItem>
								<Divider />
								<MenuItem onClick={handleSignOut}>
									<ListItemIcon>
										<Logout fontSize="small" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Menu>
						</div>
					</div>
					<div className="relative flex flex-auto flex-col">
						<Outlet />
						<TermsAgreementModal />	
					</div>
				</div>
			</div>
		</div>
	</>)
}